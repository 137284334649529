import { makeAutoObservable, runInAction } from "mobx";
import * as api from "../api";

class AccountStore {
    _id = null;
    account = null;
    events = [];
    isLoading = false;
    
    constructor(_id) {
        makeAutoObservable(this);
        this._id = _id;
    }

    async fetchData() {
        runInAction(() => this.isLoading = true);
        const { data: events } = await api.getAccountEvents(this._id);
        this.events = events.map(event => event._id);
        runInAction(() => this.isLoading = false);
    }
}

export default AccountStore;