import { getTotalOrderAmount, getTotalToPayAfterCoupon } from "./prices";
import { EventOrderStatuses, FeeCollectionTypes } from "../consts";
import { calculateAge, isValidDate } from "./minimal/formatTime";


export const getTicketActualPendingNumber = (ticket) => {
    return (ticket.pending_count - (ticket.sold_count + ticket.cancel_count))
}

export const getTicketActualAvailableNumber = (ticket) => {
    return (ticket.quantity - (ticket.pending_count - ticket.cancel_count - ticket.refund_count))
}

export const generateTicketsStats = (eventTickets) => {
    const ticketStats = { totalQuantity: 0, totalPending: 0, totalPaid: 0, totalRefunded: 0 }
    for (const ticket of eventTickets) {
        ticketStats.totalQuantity += ticket.quantity
        ticketStats.totalPaid += ticket.sold_count
        ticketStats.totalRefunded += ticket.refund_count
        ticketStats.totalPending += getTicketActualPendingNumber(ticket)
    }
    return ticketStats
}

const generateEventIncomeStats = (eventOrders) => {
    // the 'relevantOrders' are orders PayMe will charge us anyway no matter what.
    const eventIncomeStats = { sumOfAllOrders: 0, sumOfAllRefunds: 0, relevantOrders: 0, sumOfAllNimiFee: 0, amountOfAllRefunds: 0 }

    for (const eventOrder of eventOrders) {
        let orderPrice = eventOrder.price
        if (eventOrder.nimiFee) {
            orderPrice -= eventOrder.nimiFee
        }
        // The 'orderPrice' is the price already after reduce coupon amount if it had coupon
        if (eventOrder.status === EventOrderStatuses.PAID) {
            eventIncomeStats.relevantOrders++
            if (orderPrice) {
                eventIncomeStats.sumOfAllNimiFee += eventOrder.nimiFee ? eventOrder.nimiFee : 0
                eventIncomeStats.sumOfAllOrders += orderPrice
            }
        }
        if (eventOrder.status ===  EventOrderStatuses.REFUNDED || eventOrder.status === EventOrderStatuses.PARTIAL_REFUND) {
            eventIncomeStats.relevantOrders++
            eventIncomeStats.amountOfAllRefunds++
            if (orderPrice) {
                eventIncomeStats.sumOfAllOrders += orderPrice
                eventIncomeStats.sumOfAllNimiFee += eventOrder.nimiFee ? eventOrder.nimiFee : 0
                if (eventOrder.amountLeftAfterAllRefunds) {
                    const amountLeftAfterAllRefunds = Number(eventOrder.amountLeftAfterAllRefunds) / 100
                    eventIncomeStats.sumOfAllRefunds += eventOrder.price - amountLeftAfterAllRefunds // There is a reason i use the 'eventOrder.price' here. the nimiFee makes issues. still checking this.
                }
            }
        }
    }

    return eventIncomeStats
}

// Generate stats for event
export const getEventSellsStats = (allEventOrders, getCoupon, getUserEvent, getTicket, ticketsForThisEvent) => {

    const userEventsStats = { male: 0, female: 0, other: 0, maxAge: 0, minAge: 0, avgAge: 0, alreadyScanned: 0, allAges: [] }
    const ticketsStats = generateTicketsStats(ticketsForThisEvent)
    const eventIncomeStats = generateEventIncomeStats(allEventOrders)

    allEventOrders.forEach((eventOrder) => {
        /* -------------- Income Stats -------------- */

        // // let totalOrderAmount = getTotalOrderAmount(allUserEvents, getTicket)
        //
        // if (eventOrder.coupon) {
        //     // const coupon = getCoupon(eventOrder.coupon)
        //     // const allUserEvents = eventOrder.userEvents.map(getUserEvent)
        //     // totalOrderAmount = getTotalToPayAfterCoupon(totalOrderAmount, coupon, allUserEvents.length)
        // }
        // if (eventOrder.amountLeftAfterAllRefunds) {
        //     const amountLeftAfterAllRefunds = Number(eventOrder.amountLeftAfterAllRefunds) / 100
        //     // incomeStats.refundsSum += totalOrderAmount - amountLeftAfterAllRefunds
        // }
        //
        // if (eventOrder.status === EventOrderStatuses.PAID || eventOrder.status === EventOrderStatuses.PARTIAL_REFUND || eventOrder.status === EventOrderStatuses.REFUNDED) {
        //     // incomeStats.sum += totalOrderAmount
        // }
        // /* -------------- Income Stats Ends -------------- */

        const allUserEvents = eventOrder.userEvents.map(getUserEvent)
        allUserEvents.forEach(userEvent => {

            if (!userEvent) {
                // why this happens? someone deleted userEvents from the DB..
                console.log('No userEvent');
                return
            }
            // User events stats
            if (userEventsStats[userEvent.gender] || userEventsStats[userEvent.gender] === 0) {
                userEventsStats[userEvent.gender]++
            }
            if (userEvent.birth_date && isValidDate(userEvent.birth_date)) {
                const age = calculateAge(userEvent.birth_date)
                if (age && age > 18 && age < 85) { // i do this to avoid mistakes when we don't have validations on the age, so we won't present wierd numbers like 112 and -2313. it's a big todo. what we want to do with the age validations?
                    userEventsStats.allAges.push(age)
                }
            }

            if (userEvent.ticketsInfo?.length) {
                userEvent.ticketsInfo.forEach(ticketInfo => {
                    if (ticketInfo.isCheckedIn) {
                        userEventsStats.alreadyScanned++
                    }
                })
            }

        })
    })

    userEventsStats.avgAge = userEventsStats.allAges.length ? Math.round(userEventsStats.allAges.reduce((a, b) => a + b) / userEventsStats.allAges.length) : 0;
    userEventsStats.minAge = userEventsStats.allAges.length ? Math.min(...userEventsStats.allAges) : 0
    userEventsStats.maxAge = userEventsStats.allAges.length ? Math.max(...userEventsStats.allAges) : 0

    return {
        userEventsStats,
        ticketsStats,
        eventIncomeStats,
    }
}

// Generate stats for marketplace (all events and accounts) used for the admin (marketplace owner) view
export const getMarketPlaceSellStats = ({ accounts, events, eventOrders }, marketplaceData) => {

    const { marketplaceFee } = marketplaceData
    // const VAT = 1.17
    // const getProfitAmount = (totalIncomeSum, marketplaceFee) => Number((((marketplaceFee * VAT) / 100) * totalIncomeSum).toFixed(2));
    const getProfitAmount = (totalIncomeSum, marketplaceFee) => Number(((marketplaceFee / 100) * totalIncomeSum).toFixed(2));
    const getNimiFeeAmount = (profitAmount, marketplaceFee) => Number(((marketplaceFee / 100) * profitAmount).toFixed(2));

    const res = []
    const map = { accounts: {}, subAccounts: {}, eventsByAccountId: {}, eventOrdersByEventId: {} }
    const generalStats = { totalSellers: 0, totalEvents: events.length, totalEventOrders: 0, totalSumOfAllEventOrders: 0, totalSumOfAllEventOrdersRefunds: 0, totalProfit: 0, totalMoneyToSendSellers: 0 }

    const subAccounts = []

    // Organize event orders by event id
    for (const eventOrder of eventOrders) {
        if (!map.eventOrdersByEventId[eventOrder.event]) {
            map.eventOrdersByEventId[eventOrder.event] = []
        }
        map.eventOrdersByEventId[eventOrder.event].push(eventOrder)
    }
    // Organize events by account id
    for (const event of events) {
        if (!map.eventsByAccountId[event.account]) {
            map.eventsByAccountId[event.account] = []
        }
        map.eventsByAccountId[event.account].push(event)
    }

    // Organize parent and sub accounts
    for (const account of accounts) {
        if (account.isSubAccount) {
            subAccounts.push(account)
            continue
        }
        // Parent account (seller)
        generalStats.totalSellers++
        const sellerStatsRecord = {
            _id: account._id,
            accountName: account.name,
            marketFee: account.originalMarketFee,
            numberOfEvents: 0,
            numberOfEventOrders: 0,
            totalSumOfAllEventOrders: 0,
            totalSumOfAllEventOrdersRefunds: 0,
            profit: 0,
            cleanProfit: 0,
            amountToPaySeller: 0,
            subAccounts: []
        }
        map.accounts[account._id] = sellerStatsRecord
        res.push(sellerStatsRecord)
    }

    // Organize stats per single production (subAccount)
    for (const account of subAccounts) {

        const parentId = account.parent?._id
        const seller = map.accounts[parentId] // The 'seller' is the higher level account (the parent account), the subAccount is a production (הפקה)

        const eventsOfThisAccount = map.eventsByAccountId[account._id] || []
        let numOfEventOrderForThisAccount = 0
        let sumOfAllOrders = 0
        let sumOfAllRefunds = 0
        let sumOfAllProfit = 0
        let profitNeedToReduceBeforeMovingMoneyToSeller = 0
        let profitNeedToReduceOfGeneralCommissionsLikePayMe = 0
        const eventsData = []

        // Events and event orders
        for (const event of eventsOfThisAccount) {
            const eventOrders = map.eventOrdersByEventId[event._id] || []
            const eventIncomeStats = generateEventIncomeStats(eventOrders)

            numOfEventOrderForThisAccount += eventIncomeStats.relevantOrders
            sumOfAllOrders += eventIncomeStats.sumOfAllOrders
            sumOfAllRefunds += eventIncomeStats.sumOfAllRefunds
            const profitAmountForThisEvent = event.feeCollectionType === FeeCollectionTypes.ON_TOP_OF_TICKET ? eventIncomeStats.sumOfAllNimiFee : getProfitAmount(eventIncomeStats.sumOfAllOrders, account.originalMarketFee)
            sumOfAllProfit += profitAmountForThisEvent

            let profitNeedToReduceBeforeMovingMoneyToSellerPerEvent = 0
            // If the fee collection type is 'on_top_of_ticket' that means the account already got the fee when the user purchased the ticket so we don't need to reduce it now.
            if (event.feeCollectionType === FeeCollectionTypes.PERCENTAGE_FROM_TICKET) {
                profitNeedToReduceBeforeMovingMoneyToSellerPerEvent += profitAmountForThisEvent
            }

            // profitNeedToReduceBeforeMovingMoneyToSellerPerEvent += (eventIncomeStats.relevantOrders * 1) // 1 is the amount PayMe will anyway charge us because per order they are motherfuckers and we hate them.
            // profitNeedToReduceBeforeMovingMoneyToSellerPerEvent += (eventIncomeStats.amountOfAllRefunds * 5.265) // 4.5 * VAT (1.17) = 5.265 is the amount PayMe will anyway charge us per refund because they are motherfuckers and we hate them.
            profitNeedToReduceBeforeMovingMoneyToSeller += profitNeedToReduceBeforeMovingMoneyToSellerPerEvent

            profitNeedToReduceOfGeneralCommissionsLikePayMe += (eventIncomeStats.relevantOrders * 1) // 1 is the amount PayMe will anyway charge us because per order they are motherfuckers and we hate them.
            profitNeedToReduceOfGeneralCommissionsLikePayMe += (eventIncomeStats.amountOfAllRefunds * 5.265) // 4.5 * VAT (1.17) = 5.265 is the amount PayMe will anyway charge us per refund because they are motherfuckers and we hate them.

            eventsData.push({
                _id: event._id,
                account: event.account,
                isMoneyTransferred: event.isMoneyTransferred,
                eventName: event.name,
                profitAmountForThisEvent,
                amountToPaySellerForThisEvent: eventIncomeStats.sumOfAllOrders - eventIncomeStats.sumOfAllRefunds - profitNeedToReduceBeforeMovingMoneyToSellerPerEvent,
                ...eventIncomeStats
            })
        }

        generalStats.totalSumOfAllEventOrders += sumOfAllOrders
        generalStats.totalEventOrders += numOfEventOrderForThisAccount
        generalStats.totalSumOfAllEventOrdersRefunds += sumOfAllRefunds

        const singleProductionStatsRecord = {
            _id: account._id,
            accountName: account.name,
            numberOfEvents: eventsOfThisAccount ? eventsOfThisAccount.length : 0,
            numberOfEventOrders: numOfEventOrderForThisAccount,
            totalSumOfAllEventOrders: sumOfAllOrders,
            totalSumOfAllEventOrdersRefunds: sumOfAllRefunds,
            profitNeedToReduceBeforeMovingMoneyToSeller,
            profit: sumOfAllProfit - profitNeedToReduceOfGeneralCommissionsLikePayMe,
            cleanProfit: 0,
            amountToPaySeller: 0,
            subAccounts: [],
            eventsData
        }

        if (seller) {
            // singleProductionStatsRecord.cleanProfit = singleProductionStatsRecord.profit - getNimiFeeAmount(singleProductionStatsRecord.profit, marketplaceFee)
            singleProductionStatsRecord.cleanProfit = marketplaceFee / (seller.originalMarketFee ? seller.originalMarketFee : seller.marketFee) * singleProductionStatsRecord.profit // Is this correct? let's check this.
            singleProductionStatsRecord.amountToPaySeller = singleProductionStatsRecord.totalSumOfAllEventOrders - singleProductionStatsRecord.totalSumOfAllEventOrdersRefunds - singleProductionStatsRecord.profitNeedToReduceBeforeMovingMoneyToSeller

            seller.subAccounts.push(singleProductionStatsRecord)
            seller.numberOfEvents += singleProductionStatsRecord.numberOfEvents
            seller.numberOfEventOrders += singleProductionStatsRecord.numberOfEventOrders
            seller.totalSumOfAllEventOrders += singleProductionStatsRecord.totalSumOfAllEventOrders
            seller.totalSumOfAllEventOrdersRefunds += singleProductionStatsRecord.totalSumOfAllEventOrdersRefunds
            seller.profit += singleProductionStatsRecord.profit
            seller.cleanProfit += singleProductionStatsRecord.cleanProfit
            seller.amountToPaySeller += singleProductionStatsRecord.amountToPaySeller

            generalStats.totalProfit += singleProductionStatsRecord.profit
            generalStats.totalCleanProfit += singleProductionStatsRecord.cleanProfit
            generalStats.totalMoneyToSendSellers += singleProductionStatsRecord.amountToPaySeller

        } else {
            console.log('check this, no seller', account);
        }
    }

    return { res, generalStats }

}
