// routes
import { PATH_AUTH } from '../../../routes/paths';
// components
import Iconify from '../../../components/minimal/iconify';

// ----------------------------------------------------------------------
const navConfig = [
];

export default navConfig;
